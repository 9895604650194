@font-face {
	font-family: 'poppins';
	src: local('poppins'), url(./fonts/poppins-regular-webfont.ttf) format('truetype');
}

body {
	width: 100%;
	font-weight: 400;
	font-style: normal;
	font-family: 'poppins' !important;
	line-height: 1.3;
}

header {
	padding: 5px 0px;
}
h1,
h2,
h3 {
	height: auto !important;
	font-style: normal;
	margin-top: 0;
}
h4,
h5 {
	height: auto !important;
	font-weight: normal !important;
	margin-top: 0;
}
a {
	font-weight: normal !important;
}
p,
img {
	max-width: 100% !important;
}
label {
	margin-left: 0 !important;
}
label,
form label,
.label {
	font-size: 0.7em !important;
	text-transform: uppercase;
}
input,
select {
	font-size: 0.9em !important;
	font-weight: normal !important;
}
input.display-only,
textarea.display-only {
	background-color: #f3f4f8 !important;
	border: none !important;
}
textarea {
	width: 100% !important;
	min-height: 80px !important;
	font-size: 0.9em !important;
	font-weight: normal !important;
}
button {
	min-height: auto !important;
	font-size: 0.9em;
	text-align: center !important;
	border-radius: 5px !important;
	-webkit-border-radius: 5px !important;
}
button:hover {
	outline: none !important;
	box-shadow: none !important
}
button.primary {
	line-height: 1 !important;
	border: none !important;
	padding: 10px 12px !important;
}
button.button {
	border: 1px solid #999;
}
.button {
	min-height: auto !important;
	font-size: 0.9em;
	text-align: center !important;
	border: 1px solid #999;
	padding: 5px 10px !important;
	border-radius: 5px !important;
	-webkit-border-radius: 5px !important;
}
.button.primary {
	color: #fff;
	background-color: #2c63ca;
	line-height: 1 !important;
	border: none !important;
	padding: 10px 12px !important;
}
button span {
	font-size: 0.9em !important;
}
.buttonorange {
	background-color: #ffca58 !important;
}
.buttongreen {
	background-color: #00c781 !important;
}
.buttonred {
	background-color: #ff4040 !important;
}
.buttonwhatsapp {
	background-color: #039588 !important;
}
.uppercase {
	text-transform: uppercase;
}
strong,
.bold {
	font-weight: 600;
}
.complaint-list:not(:first-of-type) {
	border-top:  1px dotted #eaeaea;
	padding-top: 15px;
}
.complaint-item {
	margin-bottom: 8px;
}
.complaint-item-children {
	padding-left: 25px;
	margin-bottom: 8px;
}
.complaint-list .complaint-list {
	border: none;
	padding-left: 50px;
	padding-top: 0px;
}
.complaint-label {
	width: fit-content;
	font-size: 0.85em !important;
	line-height: 1.5 !important;
	color: #999;
	border: 1px solid #999;
    padding: 3px 7px !important;
    margin-bottom: 5px;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    display: inline-block;
}
.handling-item {
	margin-bottom: 5px;
}
.main-wrapper {
	min-height: 100vh !important;
}
.main-wrapper.production, .page-wrapper.production {
	background: #aaa !important;
}
.container {
	width: 100%;
	min-height: 100vh !important;
	position: relative;
}
.styled-anchor > span > span {
	width: 8px !important;
}
.full-height {
	min-height: 100vh !important;
}
.logo img {
	width: 130px;
}
.login-form,
.form-container > div > div {
	height: auto;
	min-height: unset !important;
}
.layer-actions {
	background-color: #fff;
	-webkit-box-shadow: 0 -2px 4px 0 rgba(0,0,0,0.1);
	box-shadow: 0 -2px 4px 0 rgba(0,0,0,0.1);
	position: fixed !important;
	bottom: 0;
	left: 0;
	z-index: 9;
}
.sidebar h4 {
	margin-bottom: 0px;
}
.sidebar > div {
	width: 100% !important;
}
.sidebar-tabs div[role="tabpanel"] {
	overflow: auto !important;
}
.sidebar-menu .sidebar-tabs button div {
	border: none !important;
	padding: 2px 7px;
	margin: 3px 5px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}
.sidebar-menu .sidebar-tabs button[aria-selected="true"] div {
	border: none;
	background-color: #2c63ca;
}
.sidebar-menu .sidebar-tabs button[aria-selected="true"] span {
	font-weight: normal;
	color: #fff;
	margin: 0px;
}
.sidebar-menu a {
	width: 100%;
	font-size: 12px;
	text-transform: uppercase;
	color: #000;
	padding: 10px 0px;
	padding-left: 40px;
}
.sidebar-menu svg {
	width: 15px;
	height: 15px;
	margin-right: 2px;
	fill: #000;
	stroke: #000;
}
.sticky-header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 3;
}
.header-search input {
	background: #fff;
	padding: 7px 15px;
	border-radius: 15px;
	-webkit-border-radius: 15px;
}
.page-wrapper {
	position: relative;
}
.page-heading {
	margin-bottom: 10px;
}
.page-heading a {
	line-height: 1;
}
.page-title {
	font-size: 1.6em !important;
	font-weight: normal !important;
	line-height: 1 !important;
	margin: 0;
}
.block-title {
	font-size: 1.3em !important;
	font-weight: normal !important;
	line-height: 1.3 !important
}
.dashboard-block {
	padding: 30px 15px;
	-webkit-box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.2);
	box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.2);
}
.dashboard-block .block-label {
	font-size: 0.9em !important;
	text-align: center;
	margin-bottom: 10px;
}
.calendar button .gKmCLF {
	font-weight: normal;
	color: #fff !important;
}
.dashboard-block .block-value {
	font-size: 1.6em;
	font-weight: 600;
}
.dashboard-shortcut a {
	font-size: 0.9em;
	font-weight: 600 !important;
	color: #000;
	margin-bottom: 10px;
}
.content-box {
	background: #fff;
	padding: 20px;
	margin-bottom: 50px;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-webkit-box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.2);
	box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.2);
}
.tabs-content > button {
	padding: 0 !important;
	margin: 0 10px 0 0 !important;
}
.tabs-content button > div {
	margin: 0 !important;
	border-bottom: 2px solid transparent;
}
.tabs-content button:not(:first-of-type) > div {
	margin-left: 10px !important;
}
.tabs-content button[aria-selected="true"] div {
	border-bottom: 2px solid #2c63ca;
}
.tabs-content button span {
	font-size: 0.8em;
	font-weight: 600;
	line-height: 1;
	color: #000;
	margin: 0 5px;
}
.tabs-content a.tab {
	font-size: 0.9em;
	font-weight: 600 !important;
	line-height: 1;
	color: #000;
	border-bottom: 2px solid transparent;
	padding: 0px 5px 6px 5px;
}
.tabs-content a.tab:hover {
	text-decoration: none;
}
.tabs-content a.tab:active,
.tabs-content a.tab.active {
	border-bottom: 2px solid #2c63ca;
}
.tabs-content a.tab:not(:first-of-type) {
	margin-left: 10px !important;
}
.tabs-content a.webtab {
	color: #2c64ca;
}

.webblue {
	color: #2c64ca;
	font-weight:bold;
}

.control-search {
	margin-right: 20px;
}
.control-search span {
	margin-right: 5px;
}
.control-search input {
	background-color: #f5f5f5;
	padding: 4px 12px !important;
}
.control-filter button {
	margin-top: 2px;
}
.control-display input {
	padding: 4px 12px !important;
	display: inline-block !important;
}
.control-display button {
	width: 90px;
	margin: 0 7px !important;
}
.control-display button div {
	margin: 0px;
}
.data-table thead span {
	font-weight: 600;
}
.data-table *,
.list-data * {
	font-size: 12px !important;
}
.data-table-more {
	position: relative;
}
.more-options {
	max-width: 120px !important;
	box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 20%);
	position: absolute;
	top: 0;
	right: 12px;
	z-index: 5;
	visibility: hidden;
}
.more-options.alt {
	width: 180px;
	max-width: 180px !important;
}
.data-table-more:hover .more-options {
	visibility: visible;
}
.more-options a {
	color: #000;
}
.statuses {
	color: #fff;
	background-color: #ccc;
	padding: 3px 10px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}
.statuses.pending {
	background-color: #9e9e9e !important;
}
.statuses.processed,
.statuses.awaiting.payment.confirmation,
.statuses.awaiting-payment-confirmation, {
	background-color: #ff9700 !important;
}
.statuses.ready.for.delivery {
	background-color: #2a63ca !important;
}
.statuses.shipping {
	background-color: #673bb7 !important;
}
.statuses.delivered {
	background-color: #039588 !important;
}
.statuses.completed,
.statuses.finished {
	background-color: #56b263 !important;
}
.statuses.void {
	background-color: #f44236 !important;
}
.data-control svg {
	width: 13px;
    height: 13px;
}
.data-control a span {
	color: #000;
}
.data-control a:not(:first-child) {
	margin-left: 22px;
}
.data-control a span span {
	width: 5px;
}
.sales-channel-field {
	font-weight: 600;
}
.data-section {
	border-bottom: 1px solid #eaeaea;
	padding: 15px 0 20px 0; 
}
.data-section:last-of-type {
	border: none !important;
}
.data-section.noborder {
	border:none;
}
.data-section.stacksection {
	padding-bottom:0;
}
.box-display {
	background-color: #f3f4f8;
	border: 1px solid #ddd;
	padding: 20px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}
.box-display-style {
	background-color: #f3f4f8;
	border: 1px solid #ddd;
	border-left: 5px solid #2c63ca;
	padding: 20px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}
.section-heading {
	font-weight: 600 !important;
}
.section-subheading {
	font-weight: 600 !important;
	border-left: 2px solid #2c63ca;
	padding-left: 7px;
	margin-bottom: 15px;
}
.formfield-row {
	min-height: unset !important;
	margin-bottom: 12px;
}
.formfield-row button > div {
	border: none !important;
}
.formfield-row div {
	border-radius: 5px;
	-webkit-border-radius: 5px;
}
.delivery-order-list .box-display:not(:last-of-type) {
	margin-bottom: 18px;
}
.options-group {
	position: relative;
}
.options-list {
	max-width: unset !important;
	padding: 5px 0px 15px 10px;
	position: absolute;
	top: 99%;
	right: 0px;
	z-index: 5;
	visibility: hidden;
}
.options-group:hover .options-list {
	visibility: visible;
}
.options-list a {
	opacity: 0;
	position: relative;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.options-list.slidein a {
	right: 25px;
}
.options-list.slidein a:nth-of-type(even) {
	right: 40px;
}
.options-list.slideinright a {
	right: -25px;
}
.options-list.slideinright a:nth-of-type(even) {
	right: -40px;
}
.options-group:hover a,
.options-group:hover a:nth-of-type(even) {
	opacity: 1;
	right: 0px;
}
.whatsapp-chat-field {
	height: 180px !important;
	max-height: 180px !important;
}
.Toastify {
	z-index:21;
}
.mobile-visible {
	display: none;
	visibility: hidden;
}

.halfscale {
	zoom:0.6;
	overflow-y: scroll;
}

.halfscale .psjwrap {
	height: 26cm;
}

.hidden {
	visibility: hidden !important;
}

@media screen and (min-width: 769px) {
	.options-list a {
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
	.mobile-visible {
		display: block;
		visibility: visible;
	}
	.mobile-hidden {
		width: 0px !important;
		display: none;
		visibility: hidden;
	}
	.sidebar-tabs div[role="tabpanel"] {
		padding-bottom: 60px;
	}
	.page-content {
		width: 100% !important;
	}
	.page-heading {
		margin-bottom: 20px;
	}
	.page-title {
		margin-bottom: 10px;
	}
	.tabs-content a.tab {
		border: 1px solid #ccc;
		padding-top: 6px;
		margin: 0px 10px 10px 0px;
		display: inline-block;
		border-radius: 5px;
		-webkit-border-radius: 5px;
		overflow: hidden;
	}
	.tabs-content a.tab:not(:first-of-type) {
		margin-left: 0px !important
	}
	.control-search {
		margin-right: 0px;
		margin-bottom: 10px;
	}
	.control-filter {
		margin-bottom: 10px;
	}
	.list-data-mobile tbody,
	.list-data-mobile tbody span {
		font-size: 0.9em !important;
	}
	table.data-table {
		width: 100% !important;
		max-width: 100% !important;
	}
	table.data-table thead {
		border: none;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
	table.data-table tr {
		border: 1px solid #999;
		display: block;
		margin-bottom: 20px;
		border-radius: 5px;
		-webkit-border-radius: 5px;
	}

	table.data-table td {
		text-align: right;
		border-bottom: 1px solid #ddd;
		display: block;
	}

	table.data-table td::before {
		/*
		* aria-label has no advantage, it won't be read inside a table
		content: attr(aria-label);
		*/
		content: attr(data-label);
		font-weight: bold;
		text-transform: uppercase;
		float: left;
	}
	table.data-table td:last-child {
		border-bottom: 0;
	}
}

.withnewline {
	white-space: pre-wrap;
}

@media all {
  .page-break {
	display: none;
  }
}

@media print {
  html, body {
	height: initial !important;
	overflow: initial !important;
	-webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
	margin-top: 1.15rem;
	display: block;
	page-break-before: auto;
  }
}